<template>
  <div>
    <div class="row">
      <a
        href="#"
        class="col-lg-4 mt-2 text-nowrap"
        data-toggle="modal"
        data-target="#resetPasswordModal"
      >
        {{ title }}
      </a>
    </div>
    <!-- Reset Password Modal -->
    <div
      class="modal fade"
      id="resetPasswordModal"
      ref="resetPasswordModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="resetPasswordModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="resetPasswordModalTitle">
              {{ title }}
            </h5>
            <button
              type="button"
              class="close"
              ref="Close"
              data-dismiss="modal"
              aria-label="Close"
              @click="resetForm"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitPasswordChange">
            <div class="modal-body">
              <div class="row" v-if="!isEmailSent && !isNewPasswordView">
                <p class="col-md-12">
                  Enter your email below to receive a code to verify your
                  account.
                </p>
              </div>
              <div class="row" v-if="!isEmailSent && !isNewPasswordView">
                <label class="col-sm-4" for="reset-email-input">Email:</label>
                <input
                  type="email"
                  required
                  class="col-sm-4 form-control"
                  id="reset-email-input"
                  v-model="userName"
                  name="email"
                />
              </div>
              <div class="row" v-if="isEmailSent">
                <p class="col-md-12">
                  If your email exists, an email containing a reset link will promptly be sent to the email provided.
                </p>
              </div>
              <div class="row" v-if="isNewPasswordView && isTokenValid">
                <p class="col-md-12">
                  Success! Please enter your new password below.
                </p>
              </div>
              <div class="row" v-if="!isTokenValid">
                <p class="col-md-12">
                  Invalid reset token. Please try again.
                </p>
              </div>
              <div class="row mt-2" v-if="isNewPasswordView && isTokenValid">
                <div class="col-md-12">
                  <input
                    type="hidden"
                    name="resetToken"
                    class="form-control"
                    v-model="resetToken"
                    id="reset-code-textarea"
                  >
                </div>
              </div>
              <div class="row mt-2" v-if="isNewPasswordView && isTokenValid">
                <label class="col-sm-4" for="new-password-input">
                  New Password:
                </label>
                <input
                  v-model="newPassword"
                  type="password"
                  class="col-sm-7 form-control"
                  name="newPassword"
                  id="new-password-input"
                />
              </div>
              <div class="row" v-if="showPasswordMessage">
                <p class="text-danger col-md-12">This field is required.</p>
              </div>
              <div class="row mt-2" v-if="isNewPasswordView && isTokenValid">
                <label class="col-sm-4" for="confirm-password-input">
                  Confirm Password:
                </label>
                <input
                  class="col-sm-7 form-control"
                  type="password"
                  v-model="confirmPassword"
                  id="confirm-password-input"
                  name="confirmPassword"
                />
              </div>
              <div class="row" v-if="showMissingConfirmPasswordMessage">
                <p class="text-danger col-md-12">
                  This field is required.
                </p>
              </div>
              <div class="row" v-if="showMismatchPasswordMessage">
                <p class="text-danger col-md-12">
                  Passwords do not match.
                </p>
              </div>
              <div class="row" v-if="doesNotMatchStandard">
                <p class="text-danger col-md-12">
                  The password does not match the requirements. Please see
                  below.
                </p>
              </div>
              <div class="row" v-if="isNewPasswordView && isTokenValid">
                <p class="text col-md-12">
                  *Password must contain at least 6 characters, one lowercase
                  letter, one uppercase letter and one special character.
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <button
                  v-if="isNewPasswordView && isTokenValid"
                  class="btn purple-btn"
                  type="button"
                  :disabled="isSubmitDisabled"
                  @click="sendResetToken()"
                >
                  Submit
                </button>
                <button
                  v-if="!isEmailSent && !isNewPasswordView"
                  type="submit"
                  class=" btn purple-btn"
                  :disabled="isSendDisabled"
                >
                  Send
                </button>
                <button
                  @click="closeModal()"
                  v-if="isEmailSent && !isNewPasswordView || !isTokenValid"
                  type="button"
                  data-dismiss="modal"
                  class=" btn purple-btn"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :can-cancel="false"></loading>
    </div>
  </div>
</template>

<script>
import { userService } from "../../_services/index";
import Loading from "vue-loading-overlay";
import {Modal} from "bootstrap";
export default {
  props: {
    title: {
      type: String,
      default: 'Forgot Password?'
    }
  },
  components: {
    Loading,
  },
  data() {
    return {
      isSendDisabled: false,
      isSubmitDisabled: false,
      doesNotMatchStandard: false,
      userName: "",
      isEmailSent: false,
      isNewPasswordView: false,
      hasEnteredCorrectCode: false,
      newPassword: "",
      confirmPassword: "",
      resetToken: "",
      showResetTokenMessage: false,
      showPasswordMessage: false,
      showMissingConfirmPasswordMessage: false,
      showMismatchPasswordMessage: false,
      isLoading: false,
      isTokenValid: true,
    };
  },
  created() {
    this.userName = this.$route.query.email;
    this.resetToken = this.$route.query.resetCode;
    if(this.userName && this.resetToken) {
      this.isNewPasswordView = true;
      this.isLoading = true;
      userService.checkResetPasswordToken(this.resetToken, this.userName).then(
        (response) => {
          this.isTokenValid = response.data.isTokenValid;
          this.isLoading = false;
        },
        () => {
          this.isTokenValid = false;
          this.isLoading = false;
        }
      );
    }
  },
  mounted() {
    if(this.userName && this.resetToken) {
      this.$nextTick(() => {
        this.modal = new Modal(this.$refs.resetPasswordModal, {});
        this.modal.show();
      });
    }
  },
  methods: {
    closeModal() {
      this.$refs.Close.click();
      this.resetForm();
    },
    submitPasswordChange() {
      this.isSendDisabled = true;
      this.isLoading = true;
      userService.sendResetPasswordEmail(this.userName).then(
        () => {
          this.isEmailSent = true;
          this.isLoading = false;
          //this.isSendDisabled = false;
        },
        () => {
          this.isEmailSent = true;
          this.isLoading = false;
        }
      );
    },
    sendResetToken() {
      if (!this.resetToken) {
        this.showResetTokenMessage = true;
        return;
      } else if (!this.newPassword) {
        this.showResetTokenMessage = false;
        this.showPasswordMessage = true;
        return;
      } else if (!this.confirmPassword) {
        this.showResetTokenMessage = false;
        this.showPasswordMessage = false;
        this.showMissingConfirmPasswordMessage = true;
        return;
      } else if (this.newPassword !== this.confirmPassword) {
        this.showResetTokenMessage = false;
        this.showPasswordMessage = false;
        this.showMissingConfirmPasswordMessage = false;
        this.showMismatchPasswordMessage = true;
        return;
      } else if (!userService.validatePasswordRequirements(this.newPassword)) {
        this.doesNotMatchStandard = true;
        return;
      }
      this.isLoading = true;
      this.isSubmitDisabled = true;
      userService
        .submitPasswordReset(this.resetToken, this.userName, this.newPassword)
        .then(
          () => {
            this.isLoading = false;
            this.$toast.success("Successfully changed password", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          },
          () => {
            this.isLoading = false;
            this.$toast.error("Failed to change user password", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          }
        );
    },
    resetForm() {
      this.isSendDisabled = false;
      this.userName = "";
      this.resetToken = "";
      this.isEmailSent = false;
      this.hasEnteredCorrectCode = false;
      this.newPassword = "";
      this.confirmPassword = "";
      this.isNewPasswordView = false;
      this.isTokenValid = true;
    },
  },
};
</script>

<style></style>
